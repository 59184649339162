import React from "react"
import Head from "../../../components/Head"
import "../../../components/FestPages/KosmoHero.scss"
import "../../../components/FestPages/FestTitles.scss"
import "../../../components/FestPages/FestContainers.scss"
import "../../../components/FestPages/FestWelcome.scss"
import "../../../components/FestPages/FestProgram.scss"
import "../../../components/FestPages/FestCooperation.scss"
import KosmoHeader from "../../../components/FestPages/KosmoHeader"
import KosmoHero from "../../../components/FestPages/KosmoHero"
import FestSection from "../../../components/FestPages/FestSection"
import FestFooter from "../../../components/FestPages/FestFooter"
const welcome = require("../../../content/festivals/kosmopolis/welcome.json")
const program = require("../../../content/festivals/kosmopolis/program.json")
const socials = require("../../../content/festivals/kosmopolis/socials.json")
const footer = require("../../../content/festivals/kosmopolis/footer.json")

const PageTitle = "12 Festiwal"

const IndexPage = () => (
  <>
    <Head pageTitle={"Between.Pomiędzy | " + PageTitle} />
    <KosmoHeader title={PageTitle} />

    <main className="fest__main">
      <KosmoHero />
      <FestSection data={welcome} />
      <FestSection data={program} />
    </main>

    <FestFooter socials={socials} footer={footer} />
  </>
)

export default IndexPage
